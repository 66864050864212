import React from 'react'
import {
  BackLink,
  Block,
  BlockImage,
  Content,
  HeadContent,
  HeadLine,
  ImageWrapper,
  PageName,
  Text,
  Wrapper,
} from '../styles/brandStoryPage'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import SubTitle from '../components/Title/SubTitle'
import SEO from '../components/seo'
import NextLink from '../components/NextLink'
import PageTransition from '../components/Animation/PageTransition'

const CONTENT_LIST = [
  {
    en: 'Concept',
    label: 'コンセプト',
    image: 'photo/concept.jpg',
    text:
      '「物理的な価値観から脱却して精神性を目覚めさせる」きっかけを創造する御守り。というコンセプトのもとで商品開発を行っています。',
  },
  {
    en: 'Thema',
    label: 'テーマ',
    image: 'photo/brand-story-summer.jpg',
    text:
      '高天原のテーマである「ご縁・繋がり」。目に見えない大切な想いをアクセサリーを通して表現し、三方良しの商いを後世に伝え、広めていく事を目的とします。',
  },
]

const BrandStoryPage = () => {
  return (
    <Wrapper>
      <SEO
        title="ブランドストーリー"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <HeadLine>
        <BackLink to="/">←</BackLink>
        <PageName>ブランドストーリー</PageName>
        <div></div>
      </HeadLine>
      <ImageWrapper>
        <Image filename="photo/site-image.jpg" />
      </ImageWrapper>
      <Content>
        <HeadContent>
          <CategoryTitle
            en="Brand Story"
            label="ブランドストーリー"
            color="white"
          />
          <Text>
            本当に大切な事は目に見えない
            この想いを形にしたいと立ち上げたブランドです。ブランド名の由来は古事記に於いて、天照大神をはじめとする神々が住んでいたとされる天上の世界を指す「高天原」からお借りしました。
          </Text>
        </HeadContent>

        {CONTENT_LIST.map(({ en, label, image, text }, index) => {
          return (
            <Block key={label}>
              <SubTitle en={en} label={label} />
              <BlockImage>
                <Image filename={image} />
              </BlockImage>
              <Text>{text}</Text>
            </Block>
          )
        })}
        <NextLink label="こだわり" path={'/feature'} />
      </Content>
      <PageTransition />
    </Wrapper>
  )
}

export default BrandStoryPage
